import request from '@/utils/request'

// 查询讣告背景图片
export function findObituaryBackground(params) {
  return request({
    url: `/obituary_background`,
    method: 'get',
    params: params
  })
}

// 更新讣告背景图片
export function updateObituaryBackground(data) {
  return request({
    url: `/obituary_background`,
    method: 'put',
    data
  })
}
