<template>
  <div class="custom-card-box">
    <a-form
      class="custom-compact-form"
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      @submit="handleSubmit"
    >
      <a-form-item label="讣告背景图片">
        <div
          style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
        >PNG/JPG格式，比例为1035:1500像素，注意背景图不要有文字、二维码信息，10MB以内
        </div>
        <upload-image
          :is-add-watermark="isAddWatermark"
          accept=".jpg,.png"
          :max-count="1"
          :max-size="10240000"
          :default-file-list="defaultFileList"
          @change="handleUploadImage"
          v-decorator="['attachments',{
            rules:[
              {validator: checkAttachments}
            ]
          }]"
        />
      </a-form-item>
    </a-form>

    <a-button style="color: white;background-color: #2d63ab;margin-left: 290px" @click="handleSubmit">保存</a-button>

  </div>
</template>

<script>
import { findObituaryBackground, updateObituaryBackground } from '@/api/obituary_background'
import UploadImage from '@/components/Upload/Image'

export default {
  name: 'SystemPackageList',
  components: {
    UploadImage
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'obituary_background' }),
      data: [],
      loading: true,
      isAddWatermark: false,
      attachments: [],
      defaultFileList: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    fetchData() {
      this.loading = true
      findObituaryBackground().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.initFormData()
        }
        this.loading = false
      })
    },

    initFormData() {
      this.defaultFileList = []
      if (this.data.images && this.data.images.length > 0) {
        this.data.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.$nextTick(() => {
        this.attachments = this.defaultFileList
      })
    },

    handleSubmit() {
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const editData = {
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          updateObituaryBackground(editData)
        }
      })
    }

  }
}
</script>
